import React from "react";
import PropTypes from "prop-types";

//mui
import { Box, Typography, Divider, Button } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../../theme";

function Details1({
  title,
  tableData,
  cellModifier,
  showAdd,
  onAdd,
  showEdit,
  onEdit,
  showBlock,
  onBlock,
  showTrip,
  showClient,
}) {
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          width: "100%",
          background: "#FFFFFF",
          borderRadius: "1rem",
          boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ paddingTop: "1rem", marginBottom: "1rem", marginLeft: "1rem" }}
        >
          {title}
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        {tableData.map((data) => (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 1rem",
              }}
            >
              <Typography
                sx={{
                  width: "10rem",
                  // display: "flex",
                  // justifyContent: "left",
                  // alignItems: "left",
                }}
              >
                {data.label}
              </Typography>
              {cellModifier ? (
                cellModifier(
                  data.id,
                  data.label,
                  data.value,
                  data.actions,
                  data.status
                )
              ) : (
                <Typography>{data.value}</Typography>
              )}
            </Box>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          </>
        ))}
        {showAdd && (
          <Button
            startIcon={<AddIcon />}
            onClick={onAdd}
            sx={{
              height: "2.25rem",
              color: "#5664D2",
              border: "1px solid rgba(86, 100, 210, 0.5)",
              borderRadius: "4rem",
              marginBottom: "0.8rem",
              marginLeft: "0.5rem",
            }}
          >
            Adauga
          </Button>
        )}
        {showEdit && (
          <Button
            startIcon={<ModeEditIcon />}
            sx={{
              height: "2.25rem",
              color: "#5664D2",
              border: "1px solid rgba(86, 100, 210, 0.5)",
              borderRadius: "4rem",
              marginBottom: "0.8rem",
              marginLeft: "0.5rem",
            }}
          >
            Editeaza
          </Button>
        )}
        {showBlock && (
          <Button
            startIcon={<LockIcon />}
            sx={{
              height: "2.25rem",
              backgroundColor: "#E91E63",
              boxShadow:
                "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
              borderRadius: "4rem",
              color: "#FFFFFF",
              marginBottom: "0.8rem",
              marginLeft: "0.5rem",
            }}
          >
            Blocheaza
          </Button>
        )}
        {showTrip && (
          <Button
            endIcon={<ArrowForwardIosIcon />}
            sx={{
              height: "2.25rem",
              color: "#5664D2",
              border: "1px solid rgba(86, 100, 210, 0.5)",
              borderRadius: "4rem",
              marginBottom: "0.8rem",
              marginLeft: "0.5rem",
            }}
          >
            Vezi Cursa
          </Button>
        )}

        {showClient && (
          <Button
            endIcon={<ArrowForwardIosIcon />}
            sx={{
              height: "2.25rem",
              color: "#5664D2",
              border: "1px solid rgba(86, 100, 210, 0.5)",
              borderRadius: "4rem",
              marginBottom: "0.8rem",
              marginLeft: "0.5rem",
            }}
          >
            Vezi Client
          </Button>
        )}
      </Box>
    </ThemeProvider>
  );
}

Details1.propTypes = {
  title: PropTypes.string,
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showBlock: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onBlock: PropTypes.func,
  cellModifier: PropTypes.func,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

Details1.defaultProps = {
  title: "Dummy",
  cellModifier: () => {},
  showAdd: false,
  showEdit: false,
  showBlock: false,
  onAdd: () => {},
  onEdit: () => {},
  onBlock: () => {},

  tableData: [],
};

export default Details1;
